exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-document-policy-cookie-js": () => import("./../../../src/pages/document/policy-cookie.js" /* webpackChunkName: "component---src-pages-document-policy-cookie-js" */),
  "component---src-pages-document-policy-organization-js": () => import("./../../../src/pages/document/policy-organization.js" /* webpackChunkName: "component---src-pages-document-policy-organization-js" */),
  "component---src-pages-document-policy-privacy-js": () => import("./../../../src/pages/document/policy-privacy.js" /* webpackChunkName: "component---src-pages-document-policy-privacy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-service-mobile-apps-js": () => import("./../../../src/pages/service/mobile-apps.js" /* webpackChunkName: "component---src-pages-service-mobile-apps-js" */),
  "component---src-pages-service-telegram-js": () => import("./../../../src/pages/service/telegram.js" /* webpackChunkName: "component---src-pages-service-telegram-js" */),
  "component---src-pages-under-development-js": () => import("./../../../src/pages/under-development.js" /* webpackChunkName: "component---src-pages-under-development-js" */),
  "component---src-template-portfolio-post-js": () => import("./../../../src/template/portfolio-post.js" /* webpackChunkName: "component---src-template-portfolio-post-js" */)
}

